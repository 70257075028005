import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import {
    CarouselProvider,
    Slider,
    Slide,
    DotGroup as CarrousselDotGroup,
    Dot as CarrousselDot,
} from "pure-react-carousel";
import api, { baseURL } from "../../services/api";
import { ContentDelimiter, Flex, isMobile, isMobileMediaQuery } from "./../../helpers/styles";
import Header from "./../../components/Header";
import SectionTitle from "../../components/SectionTitle";
import Footer from "../../components/Footer";
import Loading from "../../components/Loading";
import Button from "../../components/Button";
import TextInput from "../../components/Inputs/TextInput";
import boxHero from "../../assets/images/box_hero.png";
import boxDestaque from "../../assets/images/box_destaque.jpg";
import boxWarning from "../../assets/images/box_warning.jpg";
import boxSteps from "../../assets/images/box_steps.jpg";
import boxIcon from "../../assets/images/box_icon.png";
import boxHeroMobile from "../../assets/images/box_hero_mobile.jpg";
import boxWarningMobile from "../../assets/images/box_warning_mobile.jpg";
import boxStepsMobile from "../../assets/images/box_steps_mobile.jpg";
import modalBoxBemVindo from "../../assets/images/modalBoxBemVindo.jpg";
import modalBoxFarra from "../../assets/images/modalBoxFarra.jpg";
import modalBoxDescolado from "../../assets/images/modalBoxDescolado.jpg";
import modalBoxEstica from "../../assets/images/modalBoxEstica.jpg";
import { ReactComponent as CloseIcon } from "../../assets/images/close.svg";
import BoxItem from "../../components/BoxItem";
import Modal from "../../components/Modal";

const Box = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const boxModals = [
        {
            slug: "box-bem-vindo",
            image: modalBoxBemVindo,
            title: "Box Bem-Vindo",
            description: `Nada como ser bem recebido! O BOX BEM-VINDO tem as peças ideiais para quem acabou de chegar ao mundo.

            Aqui você encontra tudo o que seu filho precisa
            do 1º dia de vida até os 18 meses de idade. Tem body, babador, manta, macacão, mamadeira, vestido e até sapatinho. 
            
            As peças ideais para quem ainda tem cheirinho
            de bebê em um BOX na sua casa. Afinal, a sustentabilidade começa de pequeno.`,
        },
        {
            slug: "box-descolado",
            image: modalBoxDescolado,
            title: "Box Descolado",
            description: `O BOX DESCOLADO une a magia de explorar
        o mundo ao estilo que os pequenos a-do-ram.
        
        Looks modernos e divertidos para os pequenos exploradores! Roupas em animal print, xadrez, poá, estampadas, brilhantes, com mix de materiais e sobreposições. 
        
        Um BOX com peças de design e estampas atuais que destacam o seu filho em todas as ocasiões. Vem provar!`,
        },
        {
            slug: "box-farra-boa",
            image: modalBoxFarra,
            title: "Box Farra Boa",
            description: `Quem não gosta de diversão? O BOX FARRA BOA
            é perfeito para quem aproveita desde a hora de acordar até a de dormir!
            
            Pensado para crianças que adoram brincar no parquinho, correr na rua e se divertir sempre! São roupas leves e confortáveis para o dia a dia e para praticar esportes. Tem camiseta, legging, shorts, chuteira e até saia de tutu! 
            
            Um BOX repleto de peças para seu filho continuar
            a aproveitar cada minuto com liberdade, conforto
            e alegria. Experimentem e divirtam-se!
            `,
        },

        {
            slug: "box-na-estica",
            image: modalBoxEstica,
            title: "Box Na Estica",
            description: `Já pensou em ter as crianças elegantes sempre?
            O BOX NA ESTICA tem as roupas certas para os meninos e meninas que vão do clássico ao bem arrumado com muito estilo.
            
            São peças que nunca saem de moda como camisa, polo, jeans, jumper, vestido rodado
            e casinha de abelha. Não tem como errar!
            
            As roupas, acessórios e calçados para seu filho ficar muito bem vestido em todos momentos.`,
        },
    ];

    const steps = [
        {
            title: "responda ao quiz",
            text: "Aqui saberemos tudo o que seu filho gosta!",
        },
        {
            title: "receba o box",
            text: `Em até 7 dias úteis o BOX é entregue recheado 
            de peças em excelente estado.`,
        },
        {
            title: "compre só o que gostar",
            text: `Prove todas as peças com tranquilidade e selecione as que gostou.
            O valor de cada peça está na etiqueta e se quiser o BOX completo
            ainda tem 10% de desconto no boleto.`,
        },
        {
            title: "finalize sua compra",
            text: `Entre com seu login no site e selecione
            as peças desejadas e finalize sua compra.`,
        },
        {
            title: "agende a retirada",
            text: `Aguarde o contato da nossa equipe para marcar o dia
            e horário para retirar as peças que não comprar. Serviço
            válido para toda a cidade de São Paulo.`,
        },
    ];

    const [boxTypes, setBoxTypes] = useState(undefined);
    const [values, setValues] = useState({
        zip_code: "",
    });
    const [loadingResponse, setLoadingResponse] = useState(false);
    const [deliveryResponse, setDeliveryResponse] = useState(undefined);
    const [currentBoxModal, setCurrentBoxModal] = useState(undefined);

    useEffect(() => {
        loadTypes();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (boxTypes !== undefined) {
            if (window.location.hash && document.querySelector(window.location.hash)) {
                setTimeout(() => {
                    document.querySelector(window.location.hash).scrollIntoView({ behavior: "smooth", block: "start" });
                }, 100);
            }
        }
        // eslint-disable-next-line
    }, [boxTypes]);

    const loadTypes = async () => {
        let response = await api.get("/box_types?clear_cache=true");

        if (response.status === 200) {
            setBoxTypes(response.data || []);
        } else {
            setBoxTypes([]);
        }
    };

    const handleChange = (e) => {
        let {
            target: { name, value },
        } = e;
        let newValues = { ...values };

        if (name) {
            newValues[name] = value;

            setValues(newValues);
        }
    };

    const handleCheckDelivery = async () => {
        let { zip_code } = values;

        if (!zip_code.match(/^[0-9]{5,5}-[0-9]{3,3}$/g)) return;

        zip_code = zip_code.replace("-", "");

        setLoadingResponse(true);

        let response = await api
            .post(
                "/delivery/box_calculator",
                {
                    zip_code,
                },
                {
                    baseURL: baseURL.replace("v1", "v2"),
                }
            )
            .catch((err) => err.response);

        if (response && response.status === 200) {
            setDeliveryResponse(response.data);
        } else {
            setDeliveryResponse(response.data);
        }

        setLoadingResponse(false);
    };

    const handleClearDelivery = () => {
        setDeliveryResponse(undefined);
        setValues({ ...values, zip_code: "" });
    };

    const handleOpenModal = (slug) => {
        setCurrentBoxModal(boxModals.find((item) => item.slug === slug));
    };

    return (
        <>
            <Helmet>
                <title>O Box Re Petit é uma nova proposta em compras de segunda mão</title>
                <meta property="og:title" content="O Box Re Petit é uma nova proposta em compras de segunda mão" />
                <meta
                    name="description"
                    content="No nosso brechó online, baseado no seu perfil montamos uma seleção de até 40 peças do com carinho e enviamos dentro de um BOX para você"
                />
                <meta
                    property="og:description"
                    content="No nosso brechó online, baseado no seu perfil montamos uma seleção de até 40 peças do com carinho e enviamos dentro de um BOX para você"
                />
                <meta
                    name="keywords"
                    content="brechó, brechó infantil, brechó bebês, brechós, brechós infantis, roupa, bebê, bebê conforto, body, berço, enxoval, usado, segunda mão, novo, seminovo, pacote, box, seleção de roupas, box re petit"
                />
            </Helmet>

            <Header
                navigationItems={[
                    { label: "Compre", action: () => navigate("/categorias/novidades") },
                    { label: "Quero vender", action: () => navigate("/desapegue") },
                    { label: "Peça seu box", action: () => navigate("/box") },
                    { label: "Lojas Influencers", action: () => navigate("/lojas-influencers") },
                    { label: "Blog", action: () => navigate("/blog") },
                    { label: "Outlet", action: () => navigate("/outlet") },
                    { label: "Sale", action: () => navigate("/promocoes") },
                ]}
            />
            {boxTypes === undefined ? (
                <Loading />
            ) : (
                <Container>
                    <ContentDelimiter>
                        <HeaderHero
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            height={isMobile ? "620px" : "430px"}
                            background={`url(${isMobile ? boxHeroMobile : boxHero}) center / cover no-repeat`}
                        >
                            <Flex direction="column">
                                <h1>Box Re Petit</h1>
                                <hr />
                                <p>
                                    O BOX Re Petit é um serviço exclusivo do nosso brechó online. Diferente de tudo que
                                    você já viu em brechós infantis e compras de segunda mão. Baseado no seu perfil, a
                                    Re Petit faz uma seleção de até 40 peças com carinho e enviamos dentro de um BOX
                                    para você.
                                </p>
                                <Button
                                    margin="0"
                                    onClick={() => navigate("/quiz")}
                                    width={isMobile ? "230px" : "240px"}
                                    value={"Peça seu BOX"}
                                    dark
                                    filled
                                    borderradius
                                />
                            </Flex>
                        </HeaderHero>

                        <Flex width="100%" margin="6px 0 30px 0">
                            <FullImage width={isMobile ? "345px" : "533px"} margin="0" src={boxDestaque} />
                            <Flex
                                flex="1"
                                direction="column"
                                alignItems={isMobile ? "center" : "flex-start"}
                                justifyContent="flex-start"
                                margin={isMobile ? "33px auto 8px auto" : "0 auto 0 37px"}
                                maxWidth={isMobile ? "300px" : null}
                                order={isMobile ? "-1" : null}
                            >
                                <Title>O que é o box Re Petit</Title>
                                <Separator />
                                <Flex width={isMobile ? "auto" : "600px"}>
                                    <Text>
                                        {`O BOX é um serviço exclusivo para o seu filho. Totalmente diferente
de tudo que você já viu em compras de segunda mão. 

É só responder ao QUIZ para descobrir o estilo do seu filho e assinalar quais peças quer no guarda-roupa. Nós montamos uma seleção de até
40 peças com carinho e enviamos dentro de um BOX para você. 

As peças são únicas e ainda têm muitas histórias pra contar! 
E o melhor: chegam na porta da sua casa para você ter ainda mais praticidade no seu dia. `}
                                    </Text>
                                </Flex>
                            </Flex>
                        </Flex>
                    </ContentDelimiter>

                    <Flex
                        id="calculadora"
                        height={isMobile ? "auto" : "310px"}
                        width="100%"
                        margin="0"
                        background={`url(${isMobile ? boxWarningMobile : boxWarning}) center / cover no-repeat`}
                        backgroundColor={"#db803a"}
                    >
                        <Flex
                            minHeight={isMobile ? "700px" : null}
                            width="100%"
                            maxWidth="1222px"
                            direction={isMobile ? "column" : "row"}
                        >
                            <Flex
                                flex={isMobile ? "0" : "1"}
                                direction="column"
                                alignItems="flex-start"
                                justifyContent="flex-start"
                                margin={isMobile ? "40px 0 34px 0" : "0 auto 0 0"}
                            >
                                <Flex margin={isMobile ? "0 auto 31px auto" : "0 auto 31px 0"}>
                                    <Title>Fique de olho!</Title>
                                </Flex>

                                <Flex margin="0 auto 26px auto" width="70px" height="5px" background="#fff" mobile />

                                <Text maxWidth={isMobile ? "303px" : null} light small>
                                    {`O serviço de BOX está disponível 
apenas para a cidade de São Paulo e em cidades pontuais da Grande São Paulo 
(Santo André, São Bernardo do Campo e São Caetano).

Ganhe frete grátis em compras acima de R$ 700,00 na cidade de São Paulo e acima
de R$ 800,00 nas cidades citadas acima na Grande São Paulo. Consulte o valor
do frete para sua região na calculadora abaixo`}
                                </Text>
                            </Flex>
                            <Button
                                dark
                                filled
                                width="230px"
                                borderradius
                                margin={isMobile ? "0 auto auto auto" : "auto 0 auto auto"}
                                value="Peça seu BOX"
                                onClick={() => navigate("/quiz")}
                            />
                        </Flex>
                    </Flex>

                    <Flex
                        height={isMobile ? "auto" : "170px"}
                        width="100%"
                        margin="0"
                        backgroundColor={"#F7F9F9"}
                        padding={isMobile ? "40px" : null}
                        direction={isMobile ? "column" : "row"}
                        boxSizing="border-box"
                    >
                        <Flex width="100%" maxWidth="1222px">
                            <img src={boxIcon} alt="Caixa de papelão" />
                            <ShippingText margin={isMobile ? "31px auto 28px auto" : "auto 100px auto 25px"}>
                                Caso queira saber se entregamos na sua região <br />e quanto seria o frete, digite seu
                                cep aqui:
                            </ShippingText>

                            <Flex flex="1" direction="column">
                                <Flex width="100%" direction={isMobile ? "column" : "row"}>
                                    <TextInput
                                        flex="1"
                                        margin={isMobile ? "0 0 15px 0" : "auto 34px 0 0"}
                                        width={isMobile ? "100%" : "auto"}
                                        mask="99999-999"
                                        placeholder="CEP"
                                        disabled={deliveryResponse}
                                        append={
                                            values.zip_code.length > 0 &&
                                            !deliveryResponse && (
                                                <CloseButton type="button" onClick={handleClearDelivery}>
                                                    <CloseIcon />
                                                </CloseButton>
                                            )
                                        }
                                        name="zip_code"
                                        value={values.zip_code}
                                        onChange={handleChange}
                                    />
                                    <Button
                                        loading={loadingResponse}
                                        dark
                                        filled
                                        margin="auto 0 0 0"
                                        minWidth="70px"
                                        width={isMobile ? "100%" : "auto"}
                                        value={deliveryResponse ? "Limpar" : "OK"}
                                        onClick={deliveryResponse ? handleClearDelivery : handleCheckDelivery}
                                    />
                                </Flex>
                                <ShippingTip>
                                    {deliveryResponse &&
                                        (deliveryResponse.code === "not_available" ? (
                                            <>
                                                Infelizmente o serviço de box não está disponível para sua região, mas
                                                confira nossa seleção de produtos clicando AQUI
                                            </>
                                        ) : (
                                            deliveryResponse.services[0].obs
                                        ))}
                                </ShippingTip>
                            </Flex>
                        </Flex>
                    </Flex>

                    <Flex
                        width="100%"
                        minHeight={isMobile ? "1160px" : "630px"}
                        margin="0 0 30px 0"
                        background={`url(${isMobile ? boxStepsMobile : boxSteps}) center / cover no-repeat`}
                        backgroundColor={"#c32c25"}
                    >
                        <Flex
                            maxWidth={isMobile ? "310px" : "520px"}
                            margin={isMobile ? "0 auto auto auto" : "0 auto auto 50%"}
                            padding={isMobile ? "441px 0 0 0" : "55px 0 10px 0"}
                            direction="column"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                        >
                            {steps &&
                                steps.map((step, index) => (
                                    <Flex
                                        key={step.title}
                                        margin="0 0 40px 0"
                                        padding="9px 0 0 40px"
                                        position="relative"
                                        direction="column"
                                        justifyContent="flex-start"
                                        alignItems="flex-start"
                                    >
                                        <StepCircle>{index + 1}</StepCircle>
                                        <StepTitle>{step.title}</StepTitle>
                                        <StepText>{step.text}</StepText>
                                    </Flex>
                                ))}
                        </Flex>
                    </Flex>

                    <ContentDelimiter>
                        <SectionTitle>Box Personalizado</SectionTitle>

                        <Flex
                            direction={isMobile ? "column" : "row"}
                            justifyContent={isMobile ? "center" : "space-between"}
                            padding={isMobile && "0 20px"}
                            desktop
                        >
                            {boxTypes &&
                                boxTypes.map((item, index) => (
                                    <BoxItem
                                        key={item.slug}
                                        id={item.slug}
                                        title={item.title}
                                        description={item.home_description}
                                        button="Saiba Mais"
                                        background={item.image_url}
                                        horizontal={isMobile}
                                        onClick={() => handleOpenModal(item.slug)}
                                    />
                                ))}
                        </Flex>

                        <Flex width="100%" padding="0 40px" boxSizing="border-box" mobile>
                            <CarrousselContainer
                                naturalSlideWidth={272}
                                naturalSlideHeight={400}
                                totalSlides={boxTypes.length}
                                visibleSlides={1}
                            >
                                <ContentSlider>
                                    {boxTypes &&
                                        boxTypes.map((item, index) => (
                                            <Slide>
                                                <BoxItem
                                                    key={item.slug}
                                                    title={item.title}
                                                    description={item.home_description}
                                                    button="Saiba Mais"
                                                    background={item.image_url}
                                                    onClick={() => setCurrentBoxModal(index)}
                                                />
                                            </Slide>
                                        ))}
                                </ContentSlider>

                                <DotGroup
                                    renderDots={(e) => {
                                        return boxTypes.map((item, index) => (
                                            <Dot
                                                key={`slideDot_${index}`}
                                                slide={index}
                                                current={e.currentSlide === index ? 1 : 0}
                                            />
                                        ));
                                    }}
                                />
                            </CarrousselContainer>
                        </Flex>
                    </ContentDelimiter>
                </Container>
            )}
            <Footer />

            {currentBoxModal !== undefined && (
                <>
                    <Modal
                        image={boxTypes.find((item) => item.slug === currentBoxModal.slug).image_url}
                        title={currentBoxModal.title}
                        description={currentBoxModal.description}
                        callback={() => setCurrentBoxModal(undefined)}
                        button="Peça o box"
                        buttonAction={() => navigate("/quiz")}
                        show
                    />
                </>
            )}
        </>
    );
};

const Container = styled.div`
    padding-bottom: 50px;
`;

export const HeaderHero = styled(Flex)`
    padding: 0 0 0 50px;
    justify-content: flex-start;
    align-items: center;

    h1 {
        margin: 0 0 32px 0;

        font-family: "DMSerifDisplay";
        font-size: 71.5px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.85;
        letter-spacing: normal;
        text-align: left;
        color: #34343b;
    }

    hr {
        margin: 0;
        width: 70px;
        height: 5px;
        background-color: #e4858c;
        border: none;
    }

    p {
        margin: 26px auto 38px 0;

        font-family: "Graphik";
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.4;
        letter-spacing: normal;
        text-align: left;
        color: #34343b;
    }

    & > div {
        width: 385px;
        justify-content: flex-start;
        align-items: flex-start;
    }

    ${isMobileMediaQuery} {
        padding: 40px 40px 0px 40px;

        h1 {
            font-size: 41px;
            line-height: 30px;
            text-align: center;
        }

        p {
            font-size: 15px;
            line-height: 17px;
            text-align: center;
            margin-bottom: 30px;
        }

        h1,
        hr,
        p,
        button {
            margin-left: auto;
            margin-right: auto;
        }

        ${Flex} {
            margin-bottom: auto;
            width: 100%;
            justify-content: center;
            align-items: flex-start;
        }
    }
`;

const FullImage = styled.img`
    flex: 0;
    width: ${(props) => (props.width ? props.width : "100%")};
    margin: ${(props) => (props.margin ? props.margin : "0 auto")};
    height: ${(props) => (props.height ? props.height : "auto")};
    object-fit: ${(props) => (props.fit ? props.fit : "contain")};
    max-width: 100%;
`;

const Title = styled.h1`
    text-transform: uppercase;
    margin: 0;

    font-family: "SackersGothicStd";
    font-size: 21px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 4.2px;
    text-align: left;
    color: #34343b;

    ${isMobileMediaQuery} {
        text-align: center;
        line-height: 26px;
    }
`;

const Separator = styled.div`
    width: 70px;
    height: 5px;

    background-color: #f3808b;
    margin-top: 25px;
    margin-bottom: 37px;
`;

const Text = styled.div`
    max-width: ${({ maxWidth }) => maxWidth || "initial"};
    font-size: ${({ small }) => (small ? "15px" : "18px")};
    line-height: 1.4;
    color: ${({ light }) => (light ? "#fff" : "#676777")};
    white-space: pre-wrap;

    ${isMobileMediaQuery} {
        text-align: center;
        line-height: 1.14;
    }
`;

const ShippingText = styled(Flex)`
    text-transform: uppercase;
    font-family: "SackersGothicStd";
    font-size: 11px;
    font-weight: 900;
    line-height: 1.6;
    text-align: left;
    color: #34343b;
`;

const ShippingTip = styled.p`
    margin: 16px auto auto 0;

    font-family: "Graphik";
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: 0.26px;
    text-align: left;
    color: #676777;

    strong {
        font-weight: 600;
    }
`;

const CloseButton = styled.button`
    padding: 0px;
    width: 22px;
    height: 22px;
    border: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    cursor: pointer;
    z-index: 20;

    svg {
        transform: scale(0.75);
        path {
            fill: #b5b5c2;
        }
    }
`;

const CarrousselContainer = styled(CarouselProvider)`
    flex: 1;
    height: 400px;
    position: relative;
    width: 100%;
`;

const ContentSlider = styled(Slider)`
    height: 400px;
`;

const DotGroup = styled(CarrousselDotGroup)`
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    bottom: -15px;
    left: 50%;
    transform: translate(-50%, 100%);
`;

const Dot = styled(CarrousselDot)`
    padding: 0px;
    border: 0;
    background-color: #f37f8a;
    border-radius: 100%;
    height: 11px;
    width: 11px;
    flex-basis: 11px;

    &:not(:last-child) {
        margin-right: 6px;
    }

    ${(props) => !props.current && `background-color: #e4e4e7;`}
`;

const StepCircle = styled.div`
    min-width: 30px;
    min-height: 30px;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0px;
    left: 0px;
    background: #fff;
    font-family: "SackersGothicStd";
    font-size: 14px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: 2.8px;
    text-align: left;
    color: #c64c3f;
`;

const StepTitle = styled.h2`
    text-transform: uppercase;
    margin: 0 0 13px 0;

    font-family: "SackersGothicStd";
    font-size: 14px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 2.8px;
    text-align: left;
    color: #ffffff;
`;

const StepText = styled.p`
    margin: 0;
    white-space: pre-line;

    font-family: "Graphik";
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.12;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;

    ${isMobileMediaQuery} {
        white-space: initial;
    }
`;

export default Box;
