import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import Carousel from "nuka-carousel";
import { generateUUID } from "../../components/Functions";
import api from "../../services/api";
import { ContentDelimiter, Flex, isMobile, isMobileMediaQuery } from "./../../helpers/styles";
import Header from "./../../components/Header";
import SectionTitle from "../../components/SectionTitle";
import Footer from "../../components/Footer";
import Button from "../../components/Button";
import BlogItem from "../../components/BlogItem";
import BackgroundPagamentos1 from "./../../assets/images/background-pagamentos1.jpg";
import BackgroundPagamentos2 from "./../../assets/images/background-pagamentos2.jpg";
import BackgroundPagamentos3 from "./../../assets/images/background-pagamentos3.jpg";
import BackgroundPagamentos4 from "./../../assets/images/background-pagamentos4.jpg";
import BackgroundPagamentos1Mobile from "./../../assets/images/background-pagamentos1-mobile.jpg";
import BackgroundPagamentos2Mobile from "./../../assets/images/background-pagamentos2-mobile.jpg";
import BackgroundPagamentos3Mobile from "./../../assets/images/background-pagamentos3-mobile.jpg";
import BackgroundPagamentos4Mobile from "./../../assets/images/background-pagamentos4-mobile.jpg";
import StepBase from "./../../assets/images/base.svg";
import StepIcon1 from "./../../assets/images/step-1.png";
import StepIcon2 from "./../../assets/images/step-2.png";
import StepIcon3 from "./../../assets/images/step-3.png";
import StepIcon4 from "./../../assets/images/step-4.png";
import StepIcon5 from "./../../assets/images/step-5.png";
import InfoIcon1 from "./../../assets/images/info-1.png";
import InfoIcon2 from "./../../assets/images/info-2.png";
import InfoIcon3 from "./../../assets/images/info-3.png";
import InfoIcon4 from "./../../assets/images/info-4.png";
import InfoIcon5 from "./../../assets/images/info-5.png";
import StepsCarrousselMobile from "./StepsCarrousselMobile";
import Cedro from "../../assets/images/cedro-white.png";
import Unibes from "../../assets/images/unibes-white.png";
import Alquimia from "../../assets/images/alquimia-white.png";
import ProSaber from "../../assets/images/pro-saber.png";
import InstitutoC from "../../assets/images/logo_instituto_c_white.png";
import PequenoCidadao from "../../assets/images/logo_casa_do_pequeno_cidadao_branco_e_preto.png";

const Desapegue = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [state, setState] = useState(undefined);
    const user = useSelector((state) => state.user);

    useEffect(() => {
        loadHome();
        if (window.location.hash && document.querySelector(window.location.hash)) {
            setTimeout(() => {
                document.querySelector(window.location.hash).scrollIntoView({ behavior: "smooth", block: "start" });
            }, 100);
        }
        // eslint-disable-next-line
    }, []);

    const loadHome = async () => {
        let response = await api.get("/home_payments");

        if (response.status === 200) {
            setState({ ...response.data });

            // eslint-disable-next-line
            dataLayer.push({ ecommerce: null });
            // eslint-disable-next-line
            dataLayer.push({
                event: "virtualPageview",
                page: document.title,
                type: "Desapegue",
                environment: process.env.REACT_APP_ENV,
                user: [
                    {
                        name: user.first_name && user.last_name ? user.first_name + " " + user.last_name : "",
                        username: user.slug ? user.slug : "",
                        user_id: user.id ? user.id : "",
                        email: user.email ? user.email : "",
                        language: "pt-br",
                        returning: JSON.parse(localStorage.getItem("accepted_cookies")) ? true : false,
                    },
                ],
            });
        } else {
            setState({
                blog_posts: [],
            });
        }
    };

    const eventFace = async () => {
        let userSessionServerData = window.userCurrentServerData(user);
        let event_id = generateUUID();
        let event_time = Math.round(Date.now() / 1000);

        window.fbq("track", "Contact", {}, { eventID: event_id });

        api.post("/facebook_ads_event", {
            data: [
                {
                    event_name: "Contact",
                    event_id: event_id,
                    event_time: event_time,
                    event_source_url: window.location.href,
                    action_source: "website",
                    user_data: userSessionServerData,
                },
            ],
        })
            .then((res) => console.log("EVENTO Contact", res.data))
            .catch((res) => res.response);
    };

    return (
        <>
            <Helmet>
                <title>Desapegue na Re Petit, ganhe uma renda extra e ajude o nosso planeta</title>
                <meta
                    property="og:title"
                    content="Desapegue na Re Petit, ganhe uma renda extra e ajude o nosso planeta "
                />
                <meta
                    name="keywords"
                    content="brechó, brechó infantil, brechó bebês, brechós, brechós infantis, desapego roupa infantil, desapego, vender roupas infantis, desapego acessórios, venda de usados, segunda mão"
                />
                <meta
                    name="description"
                    content="A Re Petit é um brechó online onde você consegue vender roupas, acessórios, calçados, brinquedos e muito mais. Desapegue já!"
                />
                <meta
                    property="og:description"
                    content="A Re Petit é um brechó online onde você consegue vender roupas, acessórios, calçados, brinquedos e muito mais. Desapegue já!"
                />
            </Helmet>
            <Header
                navigationItems={[
                    {
                        label: "Como funciona?",
                        active: location.pathname === "/desapegue",
                        action: () => navigate("/desapegue"),
                    },
                    {
                        label: "O que vender?",
                        active: location.pathname === "/o-que-vender",
                        action: () => navigate("/o-que-vender"),
                    },
                    {
                        label: "Pagamentos",
                        active: location.pathname === "/pagamentos",
                        action: () => navigate("/pagamentos"),
                    },
                    {
                        label: "Quero Vender",
                        action: () => navigate("/quero-vender"),
                        secondary: true,
                        borderradius: true,
                    },
                ]}
            />
            <Container>
                <FullSection first centered background={isMobile ? BackgroundPagamentos1Mobile : BackgroundPagamentos1}>
                    <Flex maxWidth="350px" direction="column" margin="130px 453px 111px auto" alignItems="flex-end">
                        <h2>vem desapegar!</h2>
                        <hr />
                        <p>
                            Faça uma renda extra e use seus créditos para novas compras sempre com 10% de desconto! O
                            que não serve mais no seu filho ainda tem muita história para contar. Vem com gente!
                        </p>
                    </Flex>
                    <Flex maxWidth="350px" direction="column" margin="130px auto 111px 0" alignItems="flex-start">
                        <h2>o que vender?</h2>
                        <hr />
                        <p>
                            <>
                                Trabalhamos com roupas, acessórios, calçados, livros, brinquedos e objetos em excelente
                                estado. Faça aquela limpa no que não faz mais sentido, o planeta e seu bolso agradecem!{" "}
                                <Link to="/quero-vender">Desapegue já!</Link>
                            </>
                        </p>
                    </Flex>
                </FullSection>

                <ContentDelimiter>
                    <SectionTitle>comece hoje a vender suas roupas na repetit</SectionTitle>

                    <Flex wrap="flex-wrap" justifyContent="space-between" margin="0 0 36px 0" desktop>
                        <StepBox>
                            <StepNumber>1</StepNumber>
                            <img width="79px" height="auto" src={StepIcon1} alt="Step 1" />
                            <hr />
                            <h1>Faça o seu cadastro</h1>
                            <p>
                                Faça seu cadastro Separe as peças que quer desapegar. Lembrando que o mínimo são 20
                                itens e tudo precisa estar dentro do nosso padrão. <br />
                                <Link to="/quero-vender">Preencha aqui.</Link>
                            </p>
                        </StepBox>

                        <StepBox>
                            <StepNumber>2</StepNumber>
                            <img width="104px" height="auto" src={StepIcon2} alt="Step 2" />
                            <hr />
                            <h1>Envie suas peças</h1>
                            <p>
                                Você pode enviar suas peças pelo correio, solicitar a retirada (somente para São Paulo)
                                ou deixar em um dos nosso pontos de coleta.
                            </p>
                        </StepBox>

                        <StepBox>
                            <StepNumber>3</StepNumber>
                            <img width="90px" height="auto" src={StepIcon3} alt="Step 3" />
                            <hr />
                            <h1>Nós avaliamos</h1>
                            <p>
                                As suas peças serão avaliadas em até 30 dias úteis e você será informado(a) sobre a
                                proposta de valor.
                            </p>
                        </StepBox>

                        <StepBox>
                            <StepNumber>4</StepNumber>
                            <img width="89px" height="auto" src={StepIcon4} alt="Step 4" />
                            <hr />
                            <h1>Suas peças à venda sem preocupação</h1>
                            <p>A Re Petit cuida de todo o processo para você: manuseio, anúncio e venda!</p>
                        </StepBox>
                        <StepBox>
                            <StepNumber>5</StepNumber>
                            <img width="60px" height="auto" src={StepIcon5} alt="Step 5" />
                            <hr />
                            <h1>Vendeu faturou!</h1>
                            <p>
                                Use seu créditos para comprar com <b>desconto de 10%</b> no site ou resgate seu
                                dinheiro.
                            </p>
                        </StepBox>
                    </Flex>

                    <StepsCarrousselMobile />

                    <Flex width="100%" padding="0 43px" boxSizing="border-box" wrap="flex-wrap" margin="0 0 35px 0">
                        <StepsHint>
                            Ainda tem dúvidas? Saiba mais em <a href="/faq">Perguntas Frequentes</a> ou entre em{" "}
                            <a href="mailto:contato@repetit.com.br">Contato</a>.
                        </StepsHint>
                    </Flex>

                    <FullSection
                        curadoria
                        full
                        background={isMobile ? BackgroundPagamentos2Mobile : BackgroundPagamentos2}
                    >
                        <Flex minHeight={isMobile ? "620px" : null} width="100%" maxWidth="1222px">
                            <Flex
                                maxWidth={isMobile ? "100%" : "45%"}
                                direction="column"
                                margin={isMobile ? "40px 25px auto 25px" : "111px 0 110px auto"}
                                alignItems="flex-start"
                            >
                                <h2>
                                    {isMobile ? (
                                        <>
                                            ENVIE SUAS <br /> PEÇAS E VEM <br /> RE PETIT!
                                        </>
                                    ) : (
                                        <>ENVIE SUAS PEÇAS E VEM RE PETIT!</>
                                    )}
                                </h2>

                                <hr />

                                <p>
                                    Escolha o que funciona melhor para você. Mande suas peças pelo correio, deixe em um
                                    dos nossos pontos de coleta ou solicite a retirada (opção válida para a cidade de
                                    São Paulo).{" "}
                                    <Link to="/quero-vender">Clique aqui e preencha o cadastro para vender</Link>
                                    <br />
                                    <br />
                                    Entre em contato com a{" "}
                                    <a href="https://api.whatsapp.com/send?phone=5511976922404">nossa equipe</a> se
                                    tiver dúvidas.
                                </p>
                            </Flex>
                        </Flex>
                    </FullSection>

                    <SectionTitle barWidth="3.5%">vendendo com a re petit sobra mais tempo para curtir</SectionTitle>

                    <Flex
                        direction={isMobile ? "column" : "row"}
                        wrap="flex-wrap"
                        margin="0"
                        justifyContent={isMobile ? "center" : "space-around"}
                    >
                        <InfoBox>
                            <div>
                                <img width={isMobile ? "41px" : "31px"} height="auto" src={InfoIcon1} alt="Info 1" />
                            </div>
                            <p>Nossa equipe é expert em fazer uma inspeção criteriosa das peças.</p>
                        </InfoBox>

                        <InfoBox>
                            <div>
                                <img width={isMobile ? "38px" : "33px"} height="auto" src={InfoIcon2} alt="Info 2" />
                            </div>

                            <p>Fotografamos suas roupas, acessórios e brinquedos.</p>
                        </InfoBox>

                        <InfoBox>
                            <div>
                                <img width={isMobile ? "50px" : "38px"} height="auto" src={InfoIcon3} alt="Info 3" />
                            </div>

                            <p>Armazenamos, cuidamos e anunciamos suas peças com carinho.</p>
                        </InfoBox>

                        <InfoBox>
                            <div>
                                <img width={isMobile ? "40px" : "30px"} height="auto" src={InfoIcon4} alt="Info 4" />
                            </div>

                            <p>
                                Você pode doar o que não for selecionado para nossas ONGs parceiras e fazer o bem
                                juntos.
                            </p>
                        </InfoBox>

                        <InfoBox>
                            <div>
                                <img width={isMobile ? "44px" : "33px"} height="auto" src={InfoIcon5} alt="Info 5" />
                            </div>

                            <p>
                                Use como preferir: Resgatar seu dinheiro ou use crédito no site e ganha sempre 10% off
                                nas suas compras.
                            </p>
                        </InfoBox>
                    </Flex>

                    <Button
                        minWidth="280px"
                        width="min-content"
                        maxHeight="auto"
                        dark
                        borderradius
                        margin="30px auto"
                        padding="15px"
                        value="FALE COM NOSSA EQUIPE"
                        as="a"
                        onClick={eventFace}
                        target="_blank"
                        href="https://api.whatsapp.com/send?phone=5511976922404"
                    />

                    <FullSection
                        id="ongs"
                        ongs
                        full
                        background={isMobile ? BackgroundPagamentos4Mobile : BackgroundPagamentos4}
                    >
                        <Flex
                            maxWidth={isMobile ? "initial" : "540px"}
                            direction="column"
                            margin={isMobile ? "0 0 100% 0" : "0 auto 0 calc((100vw - 1222px) / 2)"}
                            alignItems="flex-start"
                            alignSelf="stretch"
                            background={isMobile ? "#73ccd4" : "#50cfd6"}
                            padding={isMobile ? "40px" : "46px 70px 46px 0"}
                            boxSizing="border-box"
                        >
                            <h2>
                                Desapegar <br />
                                Faz bem
                            </h2>
                            <hr />
                            <p>
                                A Re Petit também circula peças para quem precisa. Doar peças é um braço do nosso
                                projeto e funciona assim: Quando os itens enviados para nossa análise não passam no
                                processo de seleção podem ser doados, basta o fornecedor querer. A cada ano escolhemos
                                novos parceiros. Se você se identificou com a ideia vem! Vem circular, vem Re Petit!
                            </p>

                            <Flex width="100%" direction={isMobile ? "column" : "row"} justifyContent="flex-start">
                                <Flex direction="column" margin={isMobile ? "16px 0" : "16px 32px 16px 0"}>
                                    <img src={Unibes} height="70px" alt="Unibes" />
                                    <span>(2019)</span>
                                </Flex>
                                <Flex direction="column" margin={isMobile ? "16px 0" : "16px 32px 16px 0"}>
                                    <img src={Cedro} height="70px" alt="Cedro do Líbano" />
                                    <span>(2020)</span>
                                </Flex>
                                <Flex direction="column" margin={isMobile ? "16px 0 0 0" : "16px 0"}>
                                    <img src={Alquimia} height="70px" alt="Alquimia" />
                                    <span>(2021)</span>
                                </Flex>
                                <Flex
                                    as="a"
                                    href="https://prosabersp.org.br/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    direction="column"
                                    margin={isMobile ? "16px 0 0 0" : "16px 0"}
                                    textDecoration="none"
                                >
                                    <img src={ProSaber} height="95px" alt="Pró-Saber" />
                                    <span>(2022)</span>
                                </Flex>
                                <Flex direction="column" margin={isMobile ? "16px 0 0 0" : "16px 16px 16px 30px"}>
                                    <img src={InstitutoC} height="95px" alt="Instituto C" />
                                    <span>(2023)</span>
                                </Flex>
                                <Flex direction="column" margin={isMobile ? "16px 0 0 0" : "16px 0"}>
                                    <img src={PequenoCidadao} height="95px" alt="Casa do Pequeno Cidadão" />
                                    <span>(2024)</span>
                                </Flex>
                            </Flex>
                        </Flex>
                    </FullSection>

                    {state && (
                        <>
                            <SectionTitle>Dicas re petit</SectionTitle>

                            <Flex
                                direction={isMobile ? "column" : "row"}
                                alignItems="stretch"
                                justifyContent={isMobile ? "center" : "space-between"}
                                alignLast={!isMobile}
                                desktop
                            >
                                {state.blog_posts &&
                                    state.blog_posts.map((item, index) => (
                                        <BlogItem
                                            key={`blogPost_${index}`}
                                            category={item.category.title}
                                            title={item.title}
                                            description={item.quote}
                                            author={item.author.name}
                                            background={item.image_url}
                                            link={`/blog/post/${item.slug}`}
                                        />
                                    ))}
                            </Flex>

                            <Flex direction={"column"} justifyContent={"center"} padding={"0 20px"} mobile>
                                <Carousel
                                    heightMode="current"
                                    disableEdgeSwiping
                                    defaultControlsConfig={{
                                        prevButtonStyle: { display: "none" },
                                        nextButtonStyle: { display: "none" },
                                        pagingDotsStyle: { display: "none" },
                                    }}
                                    renderBottomCenterControls={(props) => (
                                        <DotGroup>
                                            {[...Array(state.blog_posts.length).keys()].map((item, index) => (
                                                <Dot
                                                    onClick={props.goToSlide.bind(null, index)}
                                                    key={`slideDot${index}`}
                                                    slide={index}
                                                    current={props.currentSlide === index ? 1 : 0}
                                                />
                                            ))}
                                        </DotGroup>
                                    )}
                                >
                                    {state.blog_posts &&
                                        state.blog_posts.map((item, index) => (
                                            <BlogItem
                                                key={`blogPost_${index}`}
                                                category={item.category.title}
                                                title={item.title}
                                                description={item.quote}
                                                author={item.author.name}
                                                background={item.image_url}
                                                link={`/blog/post/${item.slug}`}
                                                padding="0 0 40px 0"
                                            />
                                        ))}
                                </Carousel>
                            </Flex>

                            <Button
                                width="180px"
                                as={Link}
                                to="/blog"
                                dark
                                borderradius
                                value={"Ver Mais"}
                                margin="16px auto 45px auto"
                            />
                        </>
                    )}
                </ContentDelimiter>

                <FullSection duvidas background={isMobile ? BackgroundPagamentos3Mobile : BackgroundPagamentos3}>
                    <Flex width="100%" maxWidth="1222px">
                        <Flex
                            maxWidth={isMobile ? "100%" : "40%"}
                            direction="column"
                            margin={isMobile ? "40px 40px 100% 40px" : "81px auto 69px 0"}
                            alignItems={isMobile ? "center" : "flex-start"}
                        >
                            <h2>{isMobile ? "perguntas frequentes" : "dúvidas para comprar e vender?"}</h2>
                            <hr />
                            <p>
                                Fazer a economia circular com a Re Petit é simples. Se tiver dúvidas é só conferir as
                                nossas perguntas frequentes.
                            </p>
                            <Button
                                as={Link}
                                to="/faq"
                                margin="0"
                                dark
                                borderradius
                                minWidth={isMobile ? "auto" : "200px"}
                                value="ver dúvidas"
                            />
                        </Flex>
                    </Flex>
                </FullSection>
            </Container>

            <Footer />
        </>
    );
};

const Container = styled.div``;

export const StepNumber = styled.div`
    position: absolute;
    top: 0px;
    left: 0px;
    width: 64px;
    height: 64px;
    background-image: url(${StepBase});
    background-size: contain;
    background-position: 0px 0px;
    background-repeat: no-repeat;
    box-sizing: border-box;

    display: block;
    padding: 8px 10px;
    font-family: "DMSerifDisplay";
    font-size: 26px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.8;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
`;

export const StepBox = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: #f9fafa;
    box-shadow: 0px 1px 2px 0 rgba(52, 52, 59, 0.15);
    padding: 41px 26px 38px 26px;
    width: 216px;
    min-height: 360px;
    max-height: ${isMobile ? "400px" : "450px"};
    height: auto;
    align-self: stretch;
    box-sizing: border-box;

    img {
        min-height: 99px;
        object-fit: contain;
        object-position: bottom;
    }

    hr {
        margin-top: 26px;
        margin-bottom: 32px;
        width: 100%;
        border-left: 1px solid #cacad1;
    }

    h1 {
        margin: 0;
        margin-bottom: 19px;
        font-family: "DMSerifDisplay";
        font-size: 26px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.85;
        letter-spacing: normal;
        text-align: center;
        color: #84a5ac;
    }

    p {
        margin: 0;
        font-family: "Graphik";
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: normal;
        text-align: center;
        color: #676777;

        a {
            color: #e4858c;
            font-weight: bold;
            text-decoration: none;
        }
    }

    &:not(:last-child) {
        /* margin-right: 35px; */
    }
`;

export const StepsHint = styled.p`
    margin: 0;
    font-size: 15px;
    line-height: 15px;
    text-align: center;
    color: #676777;

    a {
        color: #676777;
    }

    ${isMobileMediaQuery} {
        font-size: 13px;
        line-height: 14px;
        text-align: center;
        color: #34343b;

        a {
            color: #34343b;
        }
    }
`;

const InfoBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f9fafa;
    box-shadow: 0px 1px 2px 0 rgba(52, 52, 59, 0.15);
    padding: 0 26px 0 0;
    width: 216px;
    height: 115px;
    box-sizing: border-box;

    & > div:first-child {
        display: flex;
        justify-content: center;
        align-items: center;

        background-color: #a5bcc5;
        height: 100%;
        min-width: 51px;
    }

    p {
        margin: 26px 0;
        margin-left: 13px;
        font-family: "Graphik";
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
        color: #676777;
    }

    ${isMobileMediaQuery} {
        width: 279px;

        & > div:first-child {
            min-width: 75px;
        }

        p {
            font-size: 15px;
        }

        &:not(:last-child) {
            margin-bottom: 20px;
        }
    }
`;

const FullSection = styled.div`
    background-image: url(${(props) => props.background});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    width: 100vw;
    flex: 1;
    min-height: 430px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    ${(props) =>
        props.full &&
        `
        @media screen and (min-width: 1222px) {
            margin-left: calc((-100vw + 1222px) / 2);
        }
    `}

    ${({ first }) =>
        first &&
        css`
            & > div {
                h2 {
                    font-family: "SackersGothicStd";
                    font-size: 29px;
                    font-weight: 900;
                    font-stretch: normal;
                    font-style: normal;
                    text-transform: uppercase;
                    line-height: 1.19;
                    letter-spacing: normal;
                    color: #34343b;
                }
                hr {
                    width: 70px;
                    margin: 31px 0 37px 0;
                    border: none;
                    border-top: 5px solid #fff;
                }
                p {
                    font-family: "Graphik";
                    font-size: 18px;
                    font-weight: 300;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.56;
                    letter-spacing: normal;
                    text-align: right;
                    color: #ffffff;

                    a {
                        font-weight: bold;
                        color: #ffffff;
                    }
                }
            }
        `}

    ${({ ongs }) =>
        ongs &&
        css`
            & > div {
                h2 {
                    margin: 0;
                    font-family: "SackersGothicStd";
                    font-size: 41.5px;
                    font-weight: 900;
                    font-stretch: normal;
                    font-style: normal;
                    text-transform: uppercase;
                    line-height: 1;
                    letter-spacing: normal;
                    color: #34343b;
                }
                hr {
                    width: 70px;
                    margin: 31px 0 31px 0;
                    border: none;
                    border-top: 5px solid #fff;
                }
                p {
                    font-family: "Graphik";
                    font-size: 18px;
                    font-weight: 300;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.56;
                    letter-spacing: normal;
                    text-align: left;
                    color: #ffffff;

                    a {
                        font-weight: bold;
                        color: #ffffff;
                    }
                }

                img {
                    margin-bottom: 10px;
                }

                span {
                    font-size: 14px;
                    color: #ffffff;
                }

                ${isMobileMediaQuery} {
                    h2 {
                        font-size: 20.5px;
                        text-align: center;
                        margin: 0 auto;
                    }

                    hr {
                        margin: 28px auto;
                    }

                    p {
                        font-size: 15px;
                        text-align: center;
                        margin: 0 auto;
                    }
                }
            }
        `}

    ${({ curadoria }) =>
        curadoria &&
        css`
            & > div {
                h2 {
                    margin: 0px;

                    font-family: "SackersGothicStd";
                    font-size: 29px;
                    font-weight: 900;
                    font-stretch: normal;
                    font-style: normal;
                    text-transform: uppercase;
                    line-height: 1.19;
                    letter-spacing: normal;
                    color: #34343b;
                }

                hr {
                    width: 70px;
                    margin: 31px 0 37px 0;
                    border: none;
                    border-top: 5px solid #fff;
                }

                p {
                    margin: 0px;

                    font-family: "Graphik";
                    font-size: 18px;
                    font-weight: 300;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.56;
                    letter-spacing: normal;
                    text-align: left;
                    color: #ffffff;

                    a {
                        font-weight: bold;
                        color: #ffffff;
                    }
                }

                ${isMobileMediaQuery} {
                    h2 {
                        margin: 0 auto;
                        font-size: 26px;
                        text-align: center;
                    }

                    hr {
                        margin: 27px auto 26px auto;
                    }

                    p {
                        font-size: 15px;
                        text-align: center;
                        margin-bottom: 100%;
                        padding-bottom: 20px;
                    }
                }
            }
        `}

    ${({ duvidas }) =>
        duvidas &&
        css`
            & > div {
                h2 {
                    margin: 0;

                    font-family: "SackersGothicStd";
                    font-size: 29px;
                    font-weight: 900;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.19;
                    letter-spacing: normal;
                    text-align: left;
                    color: #34343b;
                    text-transform: uppercase;
                }

                hr {
                    margin: 19px auto 24px 0;
                    width: 70px;
                    border: none;
                    border-top: 5px solid #fff;
                }

                p {
                    margin: 0;
                    margin-bottom: 38px;
                    font-family: "Graphik";
                    font-size: 18px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.56;
                    letter-spacing: normal;
                    text-align: left;
                    color: #ffffff;

                    a {
                        font-weight: bold;
                        color: #ffffff;
                    }
                }

                ${isMobileMediaQuery} {
                    h2 {
                        font-size: 20.5px;
                        text-align: center;
                    }

                    hr {
                        margin: 24px auto 26px auto;
                    }

                    p {
                        font-size: 15px;
                        text-align: center;
                    }
                }
            }
        `}

    ${(props) =>
        props.centered &&
        css`
            & > div:nth-child(1) {
                h2,
                p {
                    margin: 0;
                    padding: 0;
                    margin-left: auto;
                    text-align: right;
                }
            }

            & > div:nth-child(2) {
                h2,
                p {
                    margin: 0;
                    padding: 0;
                    margin-right: auto;
                    text-align: left !important;
                }
            }

            ${isMobileMediaQuery} {
                flex-direction: column;
                justify-content: space-between;
                min-height: 620px;

                & > div {
                    align-items: center;
                }

                & > div:nth-child(1) {
                    margin: 0;
                    padding: 0;
                    margin: 35px auto 300px auto;
                    max-width: calc(100% - 70px);

                    h2 {
                        font-size: 20px !important;
                    }
                    p {
                        font-size: 15px !important;
                        white-space: pre-line;
                        word-break: break-word;
                    }
                    h2,
                    p {
                        margin: 0 auto;
                        text-align: center !important;
                    }
                    hr {
                        margin: 20px auto !important;
                    }
                }

                & > div:nth-child(2) {
                    margin: 0;
                    padding: 0;
                    margin: 0 auto 35px auto;
                    max-width: calc(100% - 70px);

                    h2 {
                        font-size: 20px !important;
                    }
                    p {
                        font-size: 15px !important;
                        white-space: pre-line;
                        word-break: break-word;
                    }
                    h2,
                    p {
                        margin: 0 auto;
                        text-align: center !important;
                    }
                    hr {
                        margin: 20px auto !important;
                    }
                }
            }
        `}
`;

const DotGroup = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
`;

const Dot = styled.div`
    padding: 0px;
    border: 0;
    background-color: #f37f8a;
    border-radius: 100%;
    height: 11px;
    width: 11px;
    flex-basis: 11px;
    cursor: pointer;

    &:not(:last-child) {
        margin-right: 6px;
    }

    ${(props) => !props.current && `background-color: #e4e4e7;`}
`;

export default Desapegue;
